import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/blog/style.css';
import '../assets/css/blog/style2.css';
import '../assets/css/blog/additional.css';
import '../assets/css/blog/blog.css';
// import { Footer } from './Footer';
import firstimg from "../assets/css/blog/img/Blog1.jfif";
import secondimg from "../assets/css/blog/img/Blog2.jfif";
import thirdimg from "../assets/css/blog/img/Blog3.jfif";
import fourthimg from "../assets/css/blog/img/Blog4.jfif";
import fifthimg from "../assets/css/blog/img/Blog5.jfif";
import sixthimg from "../assets/css/blog/img/photo-1567468219153-4b1dea5227ea.jpeg";
import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);
export const Blog1 = () => {
  return (
    <div>
       <section className="header16 cid-u2aLj7b8ns mbr-fullscreen mbr-parallax-background" id="hero-17-u2aLj7b8ns">
        <div className="mbr-overlay" style={{ opacity: 0.3, backgroundColor: 'rgb(0, 0, 0)' }}></div>
        <div className="container-fluid">
          <div className="row">
            <div className="content-wrap  col-md-10 text-center">
              <h1 className="mbr-section-title mbr-fonts-style mbr-white mb-4 display-1">
                <strong>Vibe Blog</strong>
              </h1>
              <p className="mbr-fonts-style mbr-text mbr-white mb-4 display-7">Get ready to dive into captivating blogs and stories! Stay 
              tuned for our latest updates and immerse yourself in a world of engaging narratives and insightful content.
              Get ready to be inspired, entertained, and informed as we share our unique perspectives and experiences. Don't miss out on the adventure – 
              be prepared to embark on a journey through our compelling blogs and stories of Harifsite members</p>
              {/* <div className="mbr-section-btn"><a className="btn btn-white-outline display-7" href="#">Explore More</a></div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="blog-2 cid-u2bKxzUROd" id="blog-2-u2bKxzUROd">
      <div className="container my-4">
  <div className="row justify-content-center mb-5">
    <div className="content-head">
      <div className="mbr-section-head">
        <h4 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2" style={{ color: 'purple' }}>
          <strong>Latest Blog Posts</strong>
        </h4>
      </div>
    </div>
  </div>
  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
    <div className="col">
      <div className="card shadow-sm">
        <img src={firstimg} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="First Image" />
        <div className="card-body">
          <h5 style={{ color: 'black' }}>'DO I REALLY NEED A WEBSITE IF I HAVE SOCIAL MEDIA PAGES?'</h5>
          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">
            <Link to="/blog1">
            <button type="button" className="btn btn-sm btn-outline-secondary">Read more</button>
          </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col">
      <div className="card shadow-sm">
        <img src={secondimg} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Second Image" />
        <div className="card-body">
          <h5 style={{ color: 'black' }}>MASTERING THE ART OF WEB DEVELOPMENT: YOUR PATH TO ONLINE SUCCESS</h5>
          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">
            <Link to="/blog2">
            <button type="button" className="btn btn-sm btn-outline-secondary">Read more</button>
          </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card shadow-sm">
        <img src={thirdimg} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Second Image" />
        <div className="card-body">
          <h5 style={{ color: 'black' }}>THE EVOLUTION OF BUSINESS: UNVEILING THE POWER OF WEBSITES:</h5>
          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">
            <Link to="/blog3">
            <button type="button" className="btn btn-sm btn-outline-secondary">Read more</button>
          </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card shadow-sm">
        <img src={fourthimg} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Second Image" />
        <div className="card-body">
          <h5 style={{ color: 'black' }}>UNLEASHING THE POTENTIAL & NAVIGATING THE FUTURE OF AI</h5>
          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">
            <Link to="/blog4">
            <button type="button" className="btn btn-sm btn-outline-secondary">Read more</button>
          </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card shadow-sm">
        <img src={fifthimg} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Second Image" />
        <div className="card-body">
          <h5 style={{ color: 'black' }}>THE GROWTH OF TECH INDUSTRY IN ETHIOPIA</h5>
          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">
            <Link to="/blog5">
            <button type="button" className="btn btn-sm btn-outline-secondary">Read more</button>
          </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card shadow-sm">
        <img src={sixthimg} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Second Image" />
        <div className="card-body">
          <h5 style={{ color: 'black' }}>THE IMPACT OF AI ON DIGITAL ART</h5>
          <br />

          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">
            <Link to="/blog6">
            <button type="button" className="btn btn-sm btn-outline-secondary">Read more</button>
          </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
            </div>
        </div>
  
  <br />
  <br />
  <br />
  <br />
</section> 


    </div>
  );
};
