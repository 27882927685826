import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";

import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);

export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email
    })
  }

  const clearFields = () => {
    setEmail('');
  }

  return (
    <Col md={12}>
  <div className="newsletter-bx wow slideInUp">
    <Row>
      <Col lg={12} md={6} xl={5}>
        <h3>Subscribe<br /> & Never miss useful updates</h3>
        {status === 'sending' && <Alert>Sending...</Alert>}
        {status === 'error' && <Alert variant="danger">{message}</Alert>}
        {status === 'success' && <Alert variant="success">{message}</Alert>}
      </Col>
      <Col md={12} xl={7}>
      <form onSubmit={handleSubmit}>
  <div className="new-email-bx">
    <div className="input-container">
      <input
        value={email}
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email Address"
      />
    </div>
    <div className="upper-section button-containerl">
      <button type="submit" className="bottom-button">Submit</button>
    </div>
  </div>
</form>


        <div className="button-container hidden-on-large">
          <button type="submit" className="bottom-button">Submit</button>
        </div>
      </Col>
    </Row>
  </div>
</Col>

  
  )
}
