import { useState,useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import '../App.css';
import TrackVisibility from 'react-on-screen';
import emailjs from '@emailjs/browser';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);

export const Contact = () => {
  const form = useRef();
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  

  

  const sendEmail = (e, form) => {
    e.preventDefault();
  
    emailjs
      .sendForm('service_4gle607', 'template_epr014h', form, {
        publicKey: 'inML4DzkKY14pXu29',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setShow(true);
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
    e.target.reset();
  };
  

  return (
    <section className="contact" id="connect">
       <Modal
  show={show}
  onHide={handleClose}
  dialogClassName="custom-modal"
  centered
  style={{maxHeight: '250px'}}
>
  <Modal.Header closeButton style={{backgroundColor: 'green'}}>
    <Modal.Title>Hasrif Site</Modal.Title>
  </Modal.Header>
  <Modal.Body style={{backgroundColor: 'green', maxHeight: '100px', overflow: 'auto' }}>
    thank you for your message
  </Modal.Body>
  
</Modal>


      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Get In Touch</h2>
                <form ref={form} onSubmit={(e) => sendEmail(e, form.current)}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" name="user_name" placeholder="First name" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" name="last_name"  placeholder="Last name" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" name="user_email" placeholder="Your email" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" name="tel"  placeholder=" Phone #"/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" name="message"></textarea>
                      <button type="submit" value="Send"><span>{buttonText}</span></button>
                    </Col>
                    {
                      status.message &&
                      <Col>
                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                      </Col>
                    }
                  </Row>
                </form>

              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
