import React from 'react'
import '../assets/css/blog/style.css';
import '../assets/css/blog/style2.css';
import '../assets/css/blog/additional.css';
import '../assets/css/blog/blog.css';
import '../components/Projects';
// import { Footer } from './Footer';
import { Projects } from '../components/Projects';



export const Works = () => {
  return (
    <div>
        <section className="header16 cid-u2aLj7b8ns mbr-fullscreen mbr-parallax-background" id="hero-17-u2aLj7b8ns">
        <div className="mbr-overlay" style={{ opacity: 0.3, backgroundColor: 'rgb(0, 0, 0)' }}></div>
        <div className="container-fluid">
          <div className="row">
            <div className="content-wrap  col-md-10 text-center">
              <h1 className="mbr-section-title mbr-fonts-style mbr-white mb-4 display-1">
                <strong>we are / and our works are</strong>
              </h1>
              <p className="mbr-fonts-style mbr-text mbr-white mb-4 display-7">"At Harifsite, our mission is to provide excellent 
              web development services with a particular emphasis on quality, originality, and meticulous attention to detail.
               Our staff is dedicated to giving you a smooth, expert experience and making sure that your vision is executed with
                accuracy and originality. We are proud of our proactive strategy, frank communication, and persistent commitment
                 to going above and beyond your expectations. Our top goal is your pleasure, and we welcome the chance to work with
                  you to meet your web development goals."</p>
              {/* <div className="mbr-section-btn"><a className="btn btn-white-outline display-7" href="#">Explore More</a></div> */}
            </div>
          </div>
        </div>
      </section>
      <Projects/>
      <br />
        <br />
        <br />
        <br />
        <br />
       
        
      
    </div>
  )
}
