import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/blog/blog.css'
import { Banner } from "./components/Banner";
import { Skills } from "./components/Service";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import firstimg from "./assets/css/blog/img/Blog1.jfif";
import secondimg from "./assets/css/blog/img/Blog2.jfif";
import thirdimg from "./assets/css/blog/img/Blog3.jfif";
import { Link } from 'react-router-dom';
import {SampleBlog} from './components/SampleBlog';
import FadeComponent from './components/Fade';
// import { About } from "./components/About";
// import { Blog1  } from './components/Blog1';
// import { Blogsample} from "./components/Blogsample"
import React from 'react'
export const Main = () => {
  return (
    <div>
       
      <Banner />
      {/* <Blogs /> */}
      <Skills />
      <Projects />
        
     
      <SampleBlog />
    
      <Contact />
      
   </div>
  ) 
};
