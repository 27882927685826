import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import '../assets/css/blog/blog.css'

import firstimg from "../assets/css/blog/img/Blog1.jfif";
import secondimg from "../assets/css/blog/img/Blog2.jfif";
import thirdimg from "../assets/css/blog/img/Blog3.jfif";
import { Link } from 'react-router-dom';

import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);

export const SampleBlog = () => {
  return (
    <div>
        <section className="blog-2 cid-u2bKxzUROd" id="blog-2-u2bKxzUROd">
      <div className="container my-4">
      <div className="row justify-content-center mb-5">
      <div className="content-head">
      <div className="mbr-section-head">
        <h4 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2" style={{ color: 'purple' }}>
          <strong>Latest Blog Posts</strong>
        </h4>
      </div>
    </div>
  </div>
  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
    <div className="col">
      <div className="card shadow-sm">
        <img src={firstimg} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="First Image" />
        <div className="card-body">
          <h5 style={{ color: 'black' }}>Do I Really Need a Website If I Have Social Media Pages?</h5>

          {/* <p className="card-text" style={{ color: 'black' }}>a website can serve as a powerful marketing tool, enabling you to engage with your 
          audience.</p> */}
          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">
            <Link to="/blog1">
            <button type="button" className="btn btn-sm btn-outline-secondary">Read more</button>
          </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col">
      <div className="card shadow-sm">
        <img src={secondimg} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Second Image" />
        <div className="card-body">
          <h5 style={{ color: 'black' }}>MASTERING THE ART OF WEB DEVELOPMENT: YOUR PATH TO ONLINE SUCCESS</h5>
          {/* <p className="card-text" style={{ color: 'black' }}>discover the latest trends, tips, and insights in web development. 
          Learn how to create stunning websites

          </p> */}
          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">
            <Link to="/blog2">
            <button type="button" className="btn btn-sm btn-outline-secondary">Read more</button>
          </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="card shadow-sm">
        <img src={thirdimg} className="bd-placeholder-img card-img-top" width="100%" height="225" alt="Second Image" />
        <div className="card-body">
          <h5 style={{ color: 'black' }}>THE EVOLUTION OF BUSINESS: UNVEILING THE POWER OF WEBSITES:</h5>

          {/* <p className="card-text" style={{ color: 'black' }}>the profound impact of websites on modern businesses and their future growth.
           Discover the empact of strong online presence</p> */}
          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">
            <Link to="/blog3">
            <button type="button" className="btn btn-sm btn-outline-secondary">Read more</button>
          </Link>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      </div>

      </section>
    </div>
  )
}
