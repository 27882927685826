import React, { useEffect, useRef, useState } from 'react';
import '../assets/css/YourComponent.css';
import first from '../assets/img/banner-bg5.jpg';
import '../assets/css/style.css';

import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);

export default function FadeComponent(props) {
  const [isVisible, setVisible] = useState(false);

  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => setVisible(entry.isIntersecting));
      },
      {
        rootMargin: `-${domRef.current.clientHeight}px 0px`,
      }
    );

    observer.observe(domRef.current);

    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <div className="top-left-container">
      
      <div ref={domRef} className={`fade-in-section ${isVisible ? 'blurred-image' : 'visibleblurred-image'}`}>
        <img src={first} alt="Your Image" />
        {props.children}
      </div>
    </div>
  );
}
