import React from 'react';
import '../assets/css/blog/style.css';
import '../assets/css/blog/style2.css';
import '../assets/css/blog/additional.css';
import '../assets/css/blog/blog.css';
// import '../App.css'
import { Footer } from './Footer';


const TermsAndConditions = () => {
    const termsAndConditionsStyles = `
      .terms-and-conditions {
        color: black;
        font-size: 16px;
        line-height: 1.5;
      }
    `;
  
    return (
      <div>
        <br />
        <br />
        <br />
        <br />

        <br />

       <section class="article12 cid-u2aLj7bFwT" id="about-me-12-u2aLj7bFwT">    
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-10">
                <h3 class="mbr-section-title mbr-fonts-style mb-4 mt-0 display-2">
                <strong>Terms and conditions</strong>
                </h3>
                <p class="mbr-text mbr-fonts-style display-7">
      Terms and Conditions for Harifsite <br />
      Last Modified: January 28, 2024<br />
      <h6 style={{ fontSize: '36px' }} className="mbr-section-title mbr-fonts-style mx-4 mt-0 display-2">1. Acceptance of Terms</h6>
      By using our website harifsite.com, you agree to these Terms and Conditions. Please read them carefully
      before using the website. 
      If you do not agree to these Terms and Conditions, please do not use the website.
      Information We Process on Behalf of Customers When They Use the Subscription Service
      Our product, or the Subscription Service,
        phone number, or other information about you. We call the information that our customers submit
        ‘Customer Data’ under our Customer Terms of Service.

      We do not control the content of our customers' webpages or the types of Personal Data that our customers may choose to collect or
       manage using the Subscription Service. We store our customers' information on our service providers' servers but process it as a 
       processor under our customers’ instructions and in accordance with our Customer Terms of Service, which prohibit us from using the 
       information except as necessary to provide and improve the Subscription Service and as required by law.
      Our customers control and are responsible for correcting, deleting or updating the information they process using the Subscription
       Service and for complying with any regulations or laws that require providing notice, disclosure, and/or obtaining consent prior to
        transferring the Personal Data to Harifsite for processing purposes.<br />

      <h6 style={{ fontSize: '36px' }} className="mbr-section-title mbr-fonts-style mx-4 mt-0 display-2">2. Images and Content</h6>

      All images used on this website are sourced from Freepik and are either free for commercial use with attribution or under a Freepik free license. We ensure proper attribution is provided within the website footer or directly below the image.
      All content on this website (text, graphics, logos, etc.) is the property of Harifsite or its licensors and is protected by copyright and other intellectual property laws. You may not use any content from this website without our express written permission.

      For any queries related to this document or license please contact Freepik Support via
      www.freepik.com/profile/support<br />
      <h6 style={{ fontSize: '36px' }} className="mbr-section-title mbr-fonts-style mx-4 mt-0 display-2"> 3. User Experience Tracking</h6>

      We use Google Analytics to collect anonymous data about user activity on our website. This data helps us understand how users interact with our website and make improvements. It does not collect any personally identifiable information.
      You can learn more about Google Analytics and its privacy practices here: https://support.google.com/analytics/answer/7318509?hl=en<br />
      <h6 style={{ fontSize: '36px' }} className="mbr-section-title mbr-fonts-style mx-4 mt-0 display-2"> 4. Prohibited Activities</h6>
      
      You may not use this website for any illegal or unauthorized purpose.
      You may not interfere with the operation of this website or attempt to gain unauthorized access to our systems.
      You may not transmit any viruses, worms, or other harmful code to this website.
      You may not defame, abuse, harass, threaten, or otherwise violate the rights of others.<br />
      <h6 style={{ fontSize: '36px' }} className="mbr-section-title mbr-fonts-style mx-4 mt-0 display-2"> 5. Disclaimer of Warranties</h6>
      

      This website is provided "as is" and without warranty of any kind, express or implied. We disclaim all warranties, including, 
      but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement.<br />

      <h6 style={{ fontSize: '36px' }} className="mbr-section-title mbr-fonts-style mx-4 mt-0 display-2"> 6. Limitation of Liability</h6>
      

      We will not be liable for any damages arising out of or related to your use of this website. This includes, but is not limited to,
       direct, indirect, incidental, consequential, and punitive damages.<br />

      <h6 style={{ fontSize: '36px' }} className="mbr-section-title mbr-fonts-style mx-4 mt-0 display-2">  7. Indemnification</h6>
     

      You agree to indemnify and hold harmless Harifsite and its officers, directors, employees, agents, and licensors from and against 
      any and all claims, losses, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or related
       to your use of this website or your violation of these Terms and Conditions.<br />

      <h6 style={{ fontSize: '36px' }} className="mbr-section-title mbr-fonts-style mx-4 mt-0 display-2">  8. Governing Law</h6>
     

      These Terms and Conditions shall be governed by and construed in accordance with the laws of Tigray, Ethiopia.<br />

      <h6 style={{ fontSize: '36px' }} className="mbr-section-title mbr-fonts-style mx-4 mt-0 display-2"> 9. Entire Agreement</h6>
      

      These Terms and Conditions constitute the entire agreement between you and Harifsite regarding your use of this website.<br />

      <h6 style={{ fontSize: '36px' }} className="mbr-section-title mbr-fonts-style mx-4 mt-0 display-2">   10. Amendments0</h6>
    

      We reserve the right to modify these Terms and Conditions at any time. We will post any changes on this website. 
      Your continued use of this website after the posting of any changes constitutes your acceptance of the changes.<br />

      <h6 style={{ fontSize: '36px' }} className="mbr-section-title mbr-fonts-style mx-4 mt-0 display-2">11. Contact Us<br />
</h6>

      If you have any questions about these Terms and Conditions, please contact us at contact@harifsite.com 
      </p>
                
        </div>
        </div>
        </div>
        <br />


      </section>
      <br />

      <Footer/>
        
      </div>
    );
  };
export default TermsAndConditions;