import React, {useState, useEffect} from 'react';
import { Footer } from "./Footer";
import { Contact } from "./Contact";
import '../assets/css/contact/style.css';
import '../assets/css/blog/style.css';
import '../assets/css/blog/style2.css';
import '../assets/css/blog/additional.css';
import '../assets/css/blog/blog.css';
import FirstImg from '../assets/img/first.jpeg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import navIcon4 from '../assets/img/nav-icon4.svg';
import navIcon5 from '../assets/img/nav-icon5.svg';



import TestimonialSection from './TestimonialSection';



export const ContactUs = () => {
  const testimonials = [
    {
    img: FirstImg,
      name: 'Abel Shiferaw - Data analyist',
      content: 'members of harif site consult on designing of my website',
    },
    {
      img: FirstImg,
      name: 'Moges Zenebe - business man ',
      content: "always on time work and , "
      ,
    },
    // {
    //   img: FirstImg,
    //   name: 'Getachew Mulgeta - ',
    //   content: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti.',
   
      
    // },
    {  img: FirstImg,
      name: 'Next - is your comment',
      content: 'what could it be ?',  },
    // Add more testimonials as needed
  ];

  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  const handlePreviousTestimonial = () => {
    setCurrentTestimonialIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleNextTestimonial = () => {
    setCurrentTestimonialIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonialIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    }, 5000); // Change testimonial every 10 seconds

    return () => clearInterval(interval);
  }, [testimonials.length]);
  return (
    <div>
      <section className="header16 cid-u2aLj7b8ns mbr-fullscreen mbr-parallax-background" id="hero-17-u2aLj7b8ns">
        <div className="mbr-overlay" style={{ opacity: 0.3, backgroundColor: 'rgb(0, 0, 0)' }}></div>
        <div className="container-fluid">
          <div className="row">
            <div className="content-wrap  col-md-10 text-center">
              <h1 className="mbr-section-title mbr-fonts-style mbr-white mb-4 display-1">
                <strong>Our Customer</strong>
              </h1>
              <div className="carousel-item active">
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 d-flex justify-content-center">
                              <img src={testimonials[currentTestimonialIndex].img}
                                className="rounded-circle shadow-1 mb-4 mb-lg-0" alt="woman avatar" width="150"
                                height="150" />
                            </div>
                            <div className="col-9 col-md-9 col-lg-7 col-xl-8 text-center text-lg-start mx-auto mx-lg-0">
                            <h6 className="card-title mbr-fonts-style mb-3 display-5" style={{ color: 'black' }}>
                  <strong>{testimonials[currentTestimonialIndex].name}</strong>
                </h6>
        <p className="mb-0 pb-3" style={{ color: '#ffffff' }}>
        {testimonials[currentTestimonialIndex].content}
        </p>
      </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-mdb-target="#carouselDarkVariant"
                    data-mdb-slide="prev" onClick={handlePreviousTestimonial}>
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" data-mdb-target="#carouselDarkVariant"
                    data-mdb-slide="next" onClick={handleNextTestimonial}>
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
            </div>
          </div>
        </div>
      </section>
<Contact />
<section className="contacts01" id="contacts-1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 content-head">
            <div className="mbr-section-head mb-2">
              <h3 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2" style={{ color: 'purple' }}>
                <strong>Get In-touch with us</strong>
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-2" style={{ backgroundColor: 'white', borderRadius: '20px', width: '450px', height: '180px', padding: '35px', marginTop: '5px', marginRight: '50px' }}>
            <div className="item-wrapper">
              <div className="text-wrapper">
                <h6 className="card-title mbr-fonts-style mb-3 display-5" style={{ color: 'purple' }}>
                  <strong>Phone</strong>
                </h6>
                <p className="mbr-text mbr-fonts-style display-7" style={{ color: 'purple' }}>
                  <a href="tel:+251937035633" className="text-black">+251919151121</a> <br/>
                  <a href="tel:+251937035633" className="text-black">+251937035633</a>

                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-2" style={{ backgroundColor: 'white', borderRadius: '20px', width: '450px', height: '180px', padding: '35px', marginTop: '5px' }}>
            <div className="item-wrapper">
              <div className="text-wrapper">
                <h6 className="card-title mbr-fonts-style mb-3 display-5" style={{ color: 'purple' }}>
                  <strong>Email</strong>
                </h6>
                <p className="mbr-text mbr-fonts-style display-7" style={{ color: 'purple' }}>
                  <a href="info@harifsite.com" className="text-black">info@harifsite.com</a> <br/>
                  <a href="contact@harifsite.com" className="text-black">contact@harifsite.com</a><br />
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-2" style={{ backgroundColor: 'white', borderRadius: '20px', width: '450px', height: '180px', padding: '35px', marginTop: '5px', marginRight: '50px' }}>
            <div className="item-wrapper">
              <div className="text-wrapper">
                <h6 className="card-title mbr-fonts-style mb-3 display-5" style={{ color: 'purple' }}>
                  <strong>Address</strong>
                </h6>
                <p className="mbr-text mbr-fonts-style display-7" style={{ color: 'black' }}>
                  Ethiopia
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-2" style={{ backgroundColor: 'white', borderRadius: '20px', width: '450px', height: '180px', padding: '35px', marginTop: '5px' }}>
            <div className="item-wrapper">
              <div className="text-wrapper">
                <h6 className="card-title mbr-fonts-style mb-3 display-5" style={{ color: 'purple' }}>
                  <strong>social </strong>
                </h6>
                <p className="mbr-text mbr-fonts-style display-7" style={{ color: 'purple' }}>
                <div className="social-icon">
                <a href="https://www.facebook.com/harifsite/" target='_blank' rel='noreferrer'><img src={navIcon1} alt="facebook" /></a>
                <a href="https://t.me/harifsite" target='_blank' rel='noreferrer'><img src={navIcon2} alt="telegram" /></a>
                <a href="facebook.com" target='_blank' rel='noreferrer'><img src={navIcon3} alt="linkedin" /></a>
                <a href="facebook.com" target='_blank' rel='noreferrer'><img src={navIcon4} alt="youtube" /></a>
                <a href="https://wa.me/+251937035633" target="_blank rel="noreferrer><img src={navIcon5} alt="" /></a>
              </div>
                </p>
               </div>
          </div>
        </div>
        </div>
      </div>
    </section>
<br />
<br />
<br />
<br />
<br />
        
    </div>
  )
}
