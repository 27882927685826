import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";



export const Footer = () => {
  return (
    // <footer className="footer">
    //   <Container>
    //     <Row className="align-items-center">
    //       <MailchimpForm />
    //       <Col size={12} md={4}>
    //         <img src={logo} alt="Logo" />
    //       </Col>
    //       <Col size={12} md={4} className="text-center text-sm-end">
    //         <div className="social-icon">
    //           <a href="#"><img src={navIcon1} alt="Icon" /></a>
    //           <a href="#"><img src={navIcon2} alt="Icon" /></a>
    //           <a href="#"><img src={navIcon3} alt="Icon" /></a>
    //         </div>
    //         <p>Luxm 2024. All Rights Reserved</p>
    //       </Col>
    //     </Row>
    //   </Container>
    // </footer>
    <footer className="footer">
  <Container>
    <MailchimpForm />

    <Row className="align-items-center justify-content-center flex-column flex-sm-row">
      <Col xs={12} md={4} className="text-center">
        <img src={logo} alt="Logo" />
      </Col>
      <Col xs={12} md={4} className="text-center">
        <p>Harifsite 2024. All Rights Reserved</p>
      </Col>
      <Col xs={12} md={4} className="text-center text-sm-end">
        <div className="social-icon">
          <a href="https://www.facebook.com/harifsite/"><img src={navIcon1} alt="Icon" /></a>
          <a href="https://t.me/harifsite"><img src={navIcon2} alt="Icon" /></a>
          <a href="https://www.linkedin.com/in/mulubrhan-geberkidan-085641252/"><img src={navIcon3} alt="Icon" /></a>
        </div>
      </Col>
      <Col xs={12} md={2} className="text-center">
        <a href="/terms">Terms and Conditions</a> {/* Added link */}
      </Col>
    </Row>
  </Container>
</footer>
  )
}
